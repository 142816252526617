import React from 'react';
import { styles } from '../styles/styles';

/**
 * Premium autocomplete input component with Apple-inspired design
 * 
 * @param {Object} props - Component props
 * @param {string} props.label - Input label text
 * @param {string} props.value - Current input value
 * @param {Function} props.onChange - onChange handler function
 * @param {Array} props.options - Filtered options to display
 * @param {Function} props.onSelect - Function to call when option is selected
 * @param {Function} props.onFocus - onFocus handler function
 * @param {Function} props.onBlur - onBlur handler function
 * @param {string} props.placeholder - Input placeholder text
 */
const AutocompleteInput = ({
    label,
    value,
    onChange,
    options = [],
    onSelect,
    onFocus,
    onBlur,
    placeholder = "",
    disabled = false
}) => {
    return (
        <div style={{ ...styles.formGroup, position: "relative" }}>
            <label style={styles.label}>{label}</label>
            <div style={{ position: "relative" }}>
                <input
                    type="text"
                    value={value}
                    onChange={onChange}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    style={{
                        ...styles.input,
                        borderColor: '#E5E9EF'
                    }}
                    disabled={disabled}
                />
                {value && (
                    <button
                        type="button"
                        onClick={() => {
                            onChange({ target: { value: '' } });
                            onFocus();
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        style={{
                            position: 'absolute',
                            right: '12px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            background: 'none',
                            border: 'none',
                            padding: '4px',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            color: '#8792A2',
                            zIndex: 2,
                        }}
                        title="Clear input"
                    >
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                )}
            </div>

            {options.length > 0 && (
                <ul style={{
                    ...styles.autocomplete,
                    animationName: 'fadeIn',
                    animationDuration: '0.2s'
                }}>
                    {options.map((option, idx) => (
                        <li
                            key={idx}
                            onClick={() => onSelect(option)}
                            style={{
                                ...styles.autocompleteItem,
                                backgroundColor: option === value ? 'rgba(0, 122, 255, 0.1)' : styles.autocompleteItem.backgroundColor
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {option}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AutocompleteInput; 