import React, { useState, useEffect, useCallback } from "react";
import { db, auth, login, logout } from "./firebase"; // Firebase setup in firebase.js
import { collection, addDoc, getDocs, query, where, doc, updateDoc, deleteDoc } from "firebase/firestore";
import { BrowserRouter as Router } from 'react-router-dom';

// Components
import Header from "./components/Header";
import AppContent from "./components/AppContent";

// Styles and Utils
import { styles, colors } from "./styles/styles";
import { extractUniqueValues } from "./utils/dataProcessing";

/**
 * Main App Component
 * 
 * Orchestrates the application flow and manages global state
 */
function App() {
  // User and tab state
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("inbox");

  // Data state
  const [thoughts, setThoughts] = useState([]);
  const [themes, setThemes] = useState([]);
  const [motifs, setMotifs] = useState([]);
  const [tags, setTags] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);

  // Check screen size for responsive behavior
  useEffect(() => {
    const handleResize = () => {
      // setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch user's thoughts from Firestore
  const fetchThoughts = useCallback(async (uid) => {
    if (!uid) return;

    setIsDataLoading(true);
    try {
      const q = query(collection(db, "thoughts"), where("userId", "==", uid));
      const querySnapshot = await getDocs(q);
      const thoughtsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setThoughts(thoughtsData);

      // Extract and set unique values
      const uniqueValues = extractUniqueValues(thoughtsData);
      setThemes(uniqueValues.themes);
      setMotifs(uniqueValues.motifs);
      setTags(uniqueValues.tags);
    } catch (error) {
      console.error("Error fetching thoughts:", error);
    } finally {
      setIsDataLoading(false);
    }
  }, []);

  // Listen for authentication state changes
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
      if (user) {
        console.log("User authenticated:", user.displayName);
        fetchThoughts(user.uid);
      }
    });

    return () => unsubscribe();
  }, [fetchThoughts]);

  // Save a thought to Firestore
  const saveThought = async (thoughtData, skipReload = false) => {
    if (!user) return;

    try {
      // Check if the thought has an ID (updating existing thought)
      if (thoughtData.id) {
        // Get a reference to the thought document
        const thoughtRef = doc(db, "thoughts", thoughtData.id);
        
        // Create a copy without the id property since Firestore stores it separately
        // eslint-disable-next-line no-unused-vars
        const { id, ...dataToUpdate } = thoughtData;
        
        // Update the document
        await updateDoc(thoughtRef, dataToUpdate);
      } else {
        // Creating a new thought
        await addDoc(collection(db, "thoughts"), {
          ...thoughtData,
          timestamp: new Date().toISOString(),
          userId: user.uid
        });
      }

      // Refresh thoughts list only if skipReload is false
      if (!skipReload) {
        fetchThoughts(user.uid);
      }
    } catch (error) {
      console.error("Error saving thought:", error);
    }
  };

  // Update a thought locally without triggering a Firebase reload
  const updateThoughtLocally = (updatedThought) => {
    // Update the local state directly
    setThoughts(prevThoughts => 
      prevThoughts.map(thought => 
        thought.id === updatedThought.id ? updatedThought : thought
      )
    );
  };

  // Delete a thought from Firestore
  const deleteThought = async (thoughtId) => {
    if (!user || !thoughtId) return;

    try {
      // Get a reference to the thought document
      const thoughtRef = doc(db, "thoughts", thoughtId);
      
      // Delete the document
      await deleteDoc(thoughtRef);

      // Update local state by removing the deleted thought
      setThoughts(prevThoughts => prevThoughts.filter(thought => thought.id !== thoughtId));
      
      // No need to call fetchThoughts since we're updating the state directly
    } catch (error) {
      console.error("Error deleting thought:", error);
    }
  };

  return (
    <Router>
      {/* Add viewport meta tag to control mobile behavior */}
      <style>
        {`
          /* Add viewport meta to head programmatically */
          @media screen {
            head {
              display: block;
            }
            
            head::before {
              content: ' ';
              display: block;
            }
            
            meta[name="viewport"] {
              display: block;
              content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover';
            }
          }
        `}
      </style>
      
      <div style={{
        ...styles.container,
        padding: 0, // Remove all padding completely
        backgroundColor: colors.background, // Use the dark background
      }}>
        {/* Add dark mode styles to the document */}
        <style>
          {`
            body {
              margin: 0;
              padding: 0;
              background-color: ${colors.background};
              color: ${colors.textPrimary};
            }
            
            /* Hide scrollbars globally */
            * {
              scrollbar-width: none; /* Firefox */
              -ms-overflow-style: none; /* IE and Edge */
            }
            
            *::-webkit-scrollbar {
              display: none; /* Chrome, Safari, Opera*/
            }
            
            /* Ensure inputs and textareas have dark mode */
            input, textarea {
              color: ${colors.textPrimary} !important;
              border-color: ${colors.divider} !important;
            }
            
            input::placeholder, textarea::placeholder {
              color: ${colors.textTertiary} !important;
            }
          `}
        </style>

        {/* Header with login/logout */}
        <Header
          user={user}
          onLogin={login}
          onLogout={logout}
        />

        {user && (
          <AppContent 
            activeTab={activeTab} 
            setActiveTab={setActiveTab} 
            thoughts={thoughts} 
            saveThought={saveThought} 
            deleteThought={deleteThought}
            themes={themes} 
            motifs={motifs} 
            tags={tags} 
            isDataLoading={isDataLoading}
            updateThoughtLocally={updateThoughtLocally}
          />
        )}
      </div>
    </Router>
  );
}

export default App;