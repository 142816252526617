import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { colors } from '../styles/styles';
import { useSwipeable } from 'react-swipeable';
import SortDrawer from './SortDrawer';

/**
 * Clean, minimal inbox list component for displaying thoughts
 */
const InboxList = ({ thoughts, emptyMessage, onDeleteThought }) => {
    const [sortBy, setSortBy] = useState('recent');
    const [showSortDrawer, setShowSortDrawer] = useState(false);

    // Sort thoughts based on selected criteria
    const sortedThoughts = [...thoughts].sort((a, b) => {
        if (sortBy === 'recent') {
            // Sort by timestamp (newest first)
            return new Date(b.timestamp) - new Date(a.timestamp);
        } else if (sortBy === 'importance') {
            // Sort by importance (highest first), then by timestamp for equal importance
            const importanceA = a.importance !== undefined ? a.importance : 0;
            const importanceB = b.importance !== undefined ? b.importance : 0;
            
            if (importanceB !== importanceA) {
                return importanceB - importanceA;
            } else {
                // Secondary sort by timestamp
                return new Date(b.timestamp) - new Date(a.timestamp);
            }
        }
        return 0;
    });

    // Handle sort change
    const handleSortChange = (newSortBy) => {
        setSortBy(newSortBy);
    };

    // Empty state
    if (thoughts.length === 0) {
        return (
            <div style={{
                textAlign: "center",
                padding: "48px",
                color: colors.textSecondary,
            }}>
                <svg width="64" height="64" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ margin: '0 auto 16px', display: 'block', opacity: 0.5 }}>
                    <path d="M20 6L9 17L4 12" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                <h3 style={{ fontSize: '18px', fontWeight: '600', margin: '0 0 8px', color: colors.textPrimary }}>All clear</h3>
                <p style={{ margin: 0, maxWidth: '400px', marginLeft: 'auto', marginRight: 'auto', color: colors.textSecondary }}>
                    {emptyMessage || "Enjoy the mental pause"}
                </p>
            </div>
        );
    }

    return (
        <div style={{ width: '100%' }} className="smooth-scroll">
            {/* Header with title */}
            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                padding: '0 16px',
                height: '38px' // Standardized header height
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px'
                }}>
                    <h2 style={{
                        margin: 0,
                        fontSize: '22px',
                        fontWeight: '600',
                        color: colors.textPrimary,
                        lineHeight: '38px', // Match the container height
                    }}>
                        Inbox
                    </h2>
                    
                    {/* Sort button */}
                    <button
                        onClick={() => setShowSortDrawer(true)}
                        style={{
                            background: 'none',
                            border: 'none',
                            padding: '4px',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4 6H14M4 12H11M4 18H8M17 8L20 5M20 5L23 8M20 5V19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
                
                <span style={{
                    fontSize: '14px',
                    fontWeight: '500',
                    color: colors.textSecondary,
                    lineHeight: '38px', // Match the container height
                }}>
                    {sortedThoughts.length} {sortedThoughts.length === 1 ? 'thought' : 'thoughts'}
                </span>
            </div>

            <div className="inbox-list" style={{ 
                display: 'flex', 
                flexDirection: 'column'
            }}>
                {sortedThoughts.map((thought, index) => (
                    <InboxItem 
                        key={thought.id} 
                        thought={thought} 
                        isLast={index === sortedThoughts.length - 1}
                        onDelete={() => onDeleteThought && onDeleteThought(thought.id)}
                        delay={index * 50} // Add animation delay for staggered appearance
                    />
                ))}
            </div>

            {/* Sort drawer component */}
            <SortDrawer
                isVisible={showSortDrawer}
                onClose={() => setShowSortDrawer(false)}
                onSortChange={handleSortChange}
                currentSort={sortBy}
            />
        </div>
    );
};

/**
 * Clean, minimal inbox item with swipe functionality
 */
const InboxItem = ({ thought, isLast, onDelete, delay = 0 }) => {
    const content = thought.thought || thought.content || '';
    const [swipeState, setSwipeState] = useState({ isOpen: false, direction: null });
    const navigate = useNavigate();
    
    const handleClick = () => {
        if (!swipeState.isOpen) {
            navigate(`/thought/${thought.id}`);
        }
    };
    
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => setSwipeState({ isOpen: true, direction: 'left' }),
        onSwipedRight: () => setSwipeState({ isOpen: false, direction: 'right' }),
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
        delta: 10, // minimum swipe distance
        onSwiping: (eventData) => {
            // If horizontal movement is greater than vertical, prevent default to stop scrolling
            if (Math.abs(eventData.deltaX) > Math.abs(eventData.deltaY)) {
                eventData.event.preventDefault();
                eventData.event.stopPropagation();
            }
        }
    });
    
    // Add a touch handler to capture touch events at the start
    const handleTouchStart = (e) => {
        // We'll use this as a backup to ensure we can capture and prevent unwanted scrolling
        const initialTouch = e.touches[0];
        const touchStartX = initialTouch.clientX;
        const touchStartY = initialTouch.clientY;
        
        const handleTouchMove = (e) => {
            if (!e.touches.length) return;
            
            const touch = e.touches[0];
            const deltaX = touch.clientX - touchStartX;
            const deltaY = touch.clientY - touchStartY;
            
            // If horizontal movement is greater than vertical, prevent default
            if (Math.abs(deltaX) > Math.abs(deltaY) * 1.2) { // 1.2 multiplier for better detection
                e.preventDefault();
            }
        };
        
        document.addEventListener('touchmove', handleTouchMove, { passive: false });
        
        const cleanup = () => {
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', cleanup);
            document.removeEventListener('touchcancel', cleanup);
        };
        
        document.addEventListener('touchend', cleanup, { once: true });
        document.addEventListener('touchcancel', cleanup, { once: true });
    };
    
    return (
        <div 
            style={{ 
                position: 'relative', 
                overflow: 'hidden',
                animation: `fadeIn 0.3s ease forwards`,
                animationDelay: `${delay}ms`,
                opacity: 0, // Start invisible
                willChange: 'transform, opacity', // Optimize for animation performance
            }}
            className="list-item"
        >
            {/* Delete button (revealed when swiped left) */}
            <div 
                onClick={(e) => {
                    e.stopPropagation(); // Prevent bubbling up to the parent click handler
                    onDelete(); // Trigger the delete action
                    setSwipeState({ isOpen: false, direction: null }); // Reset swipe state
                }}
                style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: '80px',
                    backgroundColor: '#FF3B30',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    color: 'white',
                    fontWeight: '600',
                    cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                }}
            >
                Delete
            </div>
            
            {/* Main content */}
            <div 
                {...swipeHandlers}
                onTouchStart={handleTouchStart}
                onClick={handleClick}
                style={{ 
                    padding: '16px',
                    cursor: 'pointer',
                    width: '100%',
                    backgroundColor: colors.background || 'white',
                    borderBottom: isLast ? 'none' : `1px solid ${colors.divider}`,
                    transform: swipeState.isOpen ? 'translateX(-80px)' : 'translateX(0)',
                    transition: 'transform 0.3s cubic-bezier(0.2, 0, 0, 1)', // Improved transition curve
                    touchAction: 'pan-y', // Allow vertical scrolling by default
                }}
            >
                <div style={{
                    fontSize: '15px',
                    lineHeight: '1.5',
                    color: colors.textPrimary,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    WebkitLineClamp: 1,
                    WebkitBoxOrient: 'vertical',
                    whiteSpace: 'normal',
                }}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default InboxList; 