import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Tabs from './Tabs';
import ThoughtsList from './ThoughtsList';
import InboxList from './InboxList';
import ThoughtDetail from './ThoughtDetail';
import ComposePage from './ComposePage';
import FieldEditPage from './FieldEditPage';
import FloatingActionButton from './FloatingActionButton';

const AppContent = ({ activeTab, setActiveTab, thoughts, saveThought, deleteThought, updateThoughtLocally, themes, motifs, tags, isDataLoading }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation();
  
  // Check if on detail page (to remove padding when header is hidden)
  const isDetailPage = location.pathname.includes('/thought/') || 
                      location.pathname.includes('/edit/') ||
                      location.pathname.includes('/edit-field/') ||
                      location.pathname.includes('/compose');
  
  // Check screen size for responsive behavior
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Filter thoughts for the inbox view (only thoughts with no theme, motif, or tags)
  const inboxThoughts = thoughts.filter(thought => 
    (!thought.theme || thought.theme === '') && 
    (!thought.motif || thought.motif === '') && 
    (!thought.tags || thought.tags.length === 0)
  );

  // All thoughts for the regular list view
  const allThoughts = thoughts;

  // Handle deleting a thought
  const handleDeleteThought = (thoughtId) => {
    // Use the deleteThought function passed from App.js
    if (deleteThought && thoughtId) {
      deleteThought(thoughtId);
    }
  };

  return (
    <>
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: isDetailPage ? '0' : '60px', // Only add padding when header is shown
        marginTop: 0,
      }}>
        
        {/* Main content area - dynamically changes based on active tab */}
        <div style={{
          flex: 1,
          overflow: 'auto',
          padding: isMobile ? '8px' : '16px 24px',
          paddingBottom: '80px', // Extra padding for FAB
        }}>
          <Routes>
            <Route path="/" element={
              <>
                {/* Tabs for all devices */}
                <Tabs
                  activeTab={activeTab}
                  onTabChange={setActiveTab}
                  inboxCount={inboxThoughts.length}
                />

                {/* Content based on active tab */}
                {activeTab === "inbox" ? (
                  <InboxList 
                    thoughts={inboxThoughts} 
                    emptyMessage="Enjoy the mental pause"
                    onDeleteThought={handleDeleteThought}
                  />
                ) : (
                  <ThoughtsList 
                    thoughts={allThoughts}
                    title="Library" 
                    onDeleteThought={handleDeleteThought}
                  />
                )}
              </>
            } />
            <Route path="/thought/:id" element={<ThoughtDetail thoughts={thoughts} onSave={saveThought} isDataLoading={isDataLoading} updateThoughtLocally={updateThoughtLocally} />} />
            <Route path="/compose" element={<ComposePage onSave={saveThought} />} />
            <Route path="/edit/:id" element={<ComposePage 
              onSave={saveThought} 
              isEditing={true} 
              thoughts={thoughts} 
            />} />
            <Route path="/edit-field/:id/:fieldName" element={
              <FieldEditPage
                onSave={saveThought}
                thoughts={thoughts}
                themes={themes}
                motifs={motifs}
                tags={tags}
                isDataLoading={isDataLoading}
              />
            } />
          </Routes>
          <FloatingActionButton />
        </div>
      </div>
    </>
  );
};

export default AppContent; 