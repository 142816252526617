import React from 'react';
import { styles, colors } from '../styles/styles';
import { CONSTANTS } from '../styles/styles';

/**
 * Premium tags input component with Apple-inspired design
 * 
 * @param {Object} props - Component props
 * @param {string} props.tagInput - Current input value
 * @param {Array} props.selectedTags - Array of selected tags
 * @param {Array} props.filteredTags - Array of filtered tag suggestions
 * @param {boolean} props.showDropdown - Whether to show the suggestions dropdown
 * @param {Function} props.onInputChange - Handler for input changes
 * @param {Function} props.onKeyPress - Handler for key press events
 * @param {Function} props.onFocus - Handler for focus events
 * @param {Function} props.onBlur - Handler for blur events
 * @param {Function} props.onTagSelect - Handler for tag selection from dropdown
 * @param {Function} props.onTagRemove - Handler for tag removal
 */
const TagsInput = ({
    tagInput,
    selectedTags,
    filteredTags,
    showDropdown,
    onInputChange,
    onKeyPress,
    onFocus,
    onBlur,
    onTagSelect,
    onTagRemove
}) => {
    // Filter out already selected tags from the dropdown
    const availableTags = filteredTags.filter(tag => !selectedTags.includes(tag));

    return (
        <div style={{ ...styles.formGroup, position: "relative" }}>
            <label style={styles.label}>
                Tags <span style={{ fontWeight: 'normal', fontSize: '12px', color: '#8792A2' }}>({selectedTags.length}/{CONSTANTS.MAX_TAGS})</span>
            </label>

            {/* Selected tags chips - show above input when there are tags */}
            {selectedTags.length > 0 && (
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: '8px',
                    marginBottom: '8px',
                    animation: 'fadeIn 0.2s ease-out'
                }}>
                    {selectedTags.map((tag, i) => (
                        <span
                            key={i}
                            style={{
                                backgroundColor: `${colors.accent.blue}10`,
                                color: colors.accent.blue,
                                border: 'none',
                                borderRadius: '16px',
                                padding: '8px 14px',
                                fontSize: '14px',
                                fontWeight: '500',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                                transition: 'all 0.2s cubic-bezier(0.2, 0, 0, 1)',
                            }}
                            title="Click × to remove"
                        >
                            {tag}
                            <span
                                onClick={() => onTagRemove(tag)}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    width: '18px',
                                    height: '18px',
                                    borderRadius: '50%',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    marginLeft: '2px',
                                    color: colors.accent.blue,
                                    backgroundColor: 'transparent',
                                    transition: 'all 150ms ease',
                                    ":hover": {
                                        backgroundColor: `${colors.accent.blue}20`,
                                    }
                                }}
                            >
                                ×
                            </span>
                        </span>
                    ))}
                </div>
            )}

            {/* Input field with relative wrapper for positioning */}
            <div style={{ position: 'relative' }}>
                <input
                    type="text"
                    value={tagInput}
                    onChange={onInputChange}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && tagInput.trim()) {
                            e.preventDefault();
                            onTagSelect(tagInput.trim());
                        } else {
                            onKeyPress(e);
                        }
                    }}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    placeholder={selectedTags.length >= CONSTANTS.MAX_TAGS
                        ? `Maximum ${CONSTANTS.MAX_TAGS} tags reached`
                        : "Type a tag and press Enter"}
                    style={{
                        ...styles.input,
                        borderColor: '#E5E9EF'
                    }}
                    disabled={selectedTags.length >= CONSTANTS.MAX_TAGS}
                />

                {/* Indicator for the max limit */}
                {selectedTags.length > 0 && (
                    <div style={{
                        position: 'absolute',
                        right: '12px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        fontSize: '12px',
                        color: selectedTags.length >= CONSTANTS.MAX_TAGS ? '#FF453A' : '#8792A2',
                        fontWeight: selectedTags.length >= CONSTANTS.MAX_TAGS ? '600' : '400',
                    }}>
                        {selectedTags.length}/{CONSTANTS.MAX_TAGS}
                    </div>
                )}
            </div>

            {/* Dropdown suggestions - only showing unselected tags */}
            {availableTags.length > 0 && showDropdown && (
                <ul style={{
                    ...styles.autocomplete,
                    animationName: 'fadeIn',
                    animationDuration: '0.2s'
                }}>
                    {availableTags.map((tag, i) => (
                        <li
                            key={i}
                            onMouseDown={(e) => {
                                // Prevent default to avoid triggering the input's onBlur
                                e.preventDefault();
                                // Call the select handler on mouse down
                                onTagSelect(tag);
                            }}
                            style={{
                                ...styles.autocompleteItem,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}
                        >
                            <span>{tag}</span>
                            <span style={{
                                fontSize: '12px',
                                padding: '2px 6px',
                                borderRadius: '4px',
                                backgroundColor: 'rgba(0, 122, 255, 0.1)',
                                color: '#007AFF'
                            }}>
                                Add
                            </span>
                        </li>
                    ))}
                </ul>
            )}

            {/* Helper text */}
            <p style={{
                margin: '4px 0 0',
                fontSize: '12px',
                color: '#8792A2',
                fontStyle: 'italic'
            }}>
                Press Enter to add a new tag or select from suggestions
            </p>
        </div>
    );
};

export default TagsInput;