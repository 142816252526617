// Design system for Thought Log application
// Inspired by minimalist Apple and Stripe design principles

// Colors
export const colors = {
    // Primary palette
    primary: "#71B7FF", // Brighter blue for higher contrast
    primaryDark: "#5AA2EB",
    primaryLight: "#8EC5FF",

    // Grays - High contrast dark theme
    background: "#0C0C0F", // Very dark background (almost black) like Linear
    surfaceElevated: "#18181B", // Increased contrast with background
    surface: "#27272B", // Higher contrast surface color
    divider: "#383843", // More distinct dividers

    // Text - Higher contrast
    textPrimary: "#FFFFFF", // Pure white for primary text
    textSecondary: "#C2C2C7", // Lighter gray for better visibility
    textTertiary: "#8E8E99", // Brighter tertiary text
    textDisabled: "#5C5C64", // Lighter disabled text

    // Status - More vibrant for grayscale visibility
    success: "#4CD964", // Brighter green
    error: "#FF453A", // Vibrant red
    warning: "#FFD60A", // Brighter yellow

    // Tag colors - Higher contrast
    tagBackground: "#27272B",
    tagText: "#FFFFFF",

    // Accent colors for UI elements - More vibrant
    accent: {
        blue: "#71B7FF", // Brighter blue
        purple: "#B87FFF", // Vibrant purple
        indigo: "#7E9CFF", // Brighter indigo
    }
};

// Constants for the app
export const CONSTANTS = {
    MAX_DROPDOWN_ITEMS: 5,
    MAX_TAGS: 10,
};

// Typography
const typography = {
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightBold: "600",

    h1: {
        fontSize: "32px",
        lineHeight: "40px",
        fontWeight: "600",
        letterSpacing: "-0.3px",
    },
    h2: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: "600",
        letterSpacing: "-0.2px",
    },
    h3: {
        fontSize: "18px",
        lineHeight: "28px",
        fontWeight: "600",
        letterSpacing: "-0.1px",
    },
    body: {
        fontSize: "16px",
        lineHeight: "24px",
        fontWeight: "400",
    },
    label: {
        fontSize: "14px",
        lineHeight: "20px",
        fontWeight: "500",
    },
    caption: {
        fontSize: "12px",
        lineHeight: "16px",
        fontWeight: "400",
    }
};

// Spacing system based on 4px increments
const spacing = {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "24px",
    xl: "32px",
    xxl: "48px",
};

// Shadows
const shadows = {
    sm: "0 1px 3px rgba(0,0,0,0.08)",
    md: "0 4px 12px rgba(0,0,0,0.05)",
    lg: "0 12px 24px rgba(0,0,0,0.07)",
    focus: "0 0 0 4px rgba(0, 122, 255, 0.3)", // Blue focus ring
};

// Transition for animations
const transitions = {
    default: "all 200ms ease-in-out",
    fast: "all 100ms ease-in-out",
    slow: "all 300ms ease-in-out",
};

// Border radius
const borderRadius = {
    sm: "6px",
    md: "8px",
    lg: "12px",
    xl: "16px",
    circle: "50%",
};

// Component-specific styles
export const styles = {
    // Layout containers
    container: {
        maxWidth: "1000px",
        margin: "0 auto",
        padding: spacing.xl,
        fontFamily: typography.fontFamily,
        color: colors.textPrimary,
        backgroundColor: colors.background,
    },

    pageHeader: {
        marginBottom: spacing.xl,
    },

    // Tabs
    tabs: {
        display: "flex",
        marginBottom: spacing.xl,
        borderRadius: borderRadius.lg,
        padding: spacing.xs,
        backgroundColor: colors.surfaceElevated,
        boxShadow: "0 2px 8px rgba(0,0,0,0.25)",
        overflow: "hidden",
    },

    tab: (isActive) => ({
        flex: 1,
        padding: `${spacing.md} ${spacing.lg}`,
        textAlign: "center",
        cursor: "pointer",
        fontWeight: isActive ? typography.fontWeightBold : typography.fontWeightMedium,
        fontSize: typography.label.fontSize,
        lineHeight: typography.label.lineHeight,
        color: isActive ? colors.primary : colors.textSecondary,
        backgroundColor: isActive ? colors.surface : "transparent",
        borderRadius: borderRadius.md,
        transition: transitions.default,
        border: "none",
        boxShadow: isActive ? "0 2px 8px rgba(0,0,0,0.2)" : "none",
        transform: isActive ? "translateY(0)" : "translateY(0)",
        "&:hover": {
            backgroundColor: isActive ? colors.surface : colors.surfaceElevated,
            color: isActive ? colors.primary : colors.textPrimary,
        },
    }),

    // Form components
    formGroup: {
        marginBottom: spacing.lg,
    },

    label: {
        display: "block",
        marginBottom: spacing.sm,
        fontWeight: typography.fontWeightMedium,
        fontSize: typography.label.fontSize,
        color: colors.textPrimary,
    },

    input: {
        width: "100%",
        padding: spacing.md,
        fontSize: typography.body.fontSize,
        lineHeight: typography.body.lineHeight,
        color: colors.textPrimary,
        backgroundColor: colors.surface,
        borderRadius: borderRadius.md,
        border: `1px solid ${colors.divider}`,
        boxSizing: "border-box",
        transition: transitions.default,
        boxShadow: "none",
        outline: "none",
        "&:focus": {
            borderColor: colors.primary,
            boxShadow: "0 0 0 2px rgba(113, 183, 255, 0.4)",
        },
        "&:hover:not(:focus)": {
            borderColor: colors.textTertiary,
        },
        "&:disabled": {
            backgroundColor: colors.surfaceElevated,
            color: colors.textDisabled,
            cursor: "not-allowed",
        },
    },

    // Autocomplete components
    autocomplete: {
        border: `1px solid ${colors.divider}`,
        borderRadius: borderRadius.md,
        listStyle: "none",
        margin: 0,
        padding: 0,
        position: "absolute",
        backgroundColor: colors.surface,
        width: "100%",
        zIndex: 100,
        maxHeight: "240px",
        overflowY: "auto",
        boxShadow: shadows.md,
        top: "calc(100% + 4px)",
        left: 0,
    },

    autocompleteItem: {
        padding: spacing.md,
        cursor: "pointer",
        fontSize: typography.body.fontSize,
        lineHeight: typography.body.lineHeight,
        color: colors.textPrimary,
        borderBottom: `1px solid ${colors.divider}`,
        transition: transitions.fast,
        "&:last-child": {
            borderBottom: "none",
        },
        "&:hover": {
            backgroundColor: colors.surfaceElevated,
        },
    },

    // Tag chips
    tagChip: {
        display: "inline-flex",
        alignItems: "center",
        padding: `${spacing.xs} ${spacing.sm}`,
        margin: spacing.xs,
        backgroundColor: colors.tagBackground,
        color: colors.tagText,
        borderRadius: "16px",
        fontSize: typography.caption.fontSize,
        fontWeight: typography.fontWeightMedium,
        boxShadow: shadows.sm,
        transition: transitions.default,
        cursor: "default",
        "&:hover": {
            backgroundColor: "#EBF0F5",
        },
    },

    chipRemove: {
        marginLeft: spacing.xs,
        fontWeight: typography.fontWeightBold,
        color: colors.textTertiary,
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "18px",
        height: "18px",
        borderRadius: borderRadius.circle,
        transition: transitions.fast,
        "&:hover": {
            color: colors.error,
            backgroundColor: "rgba(255, 69, 58, 0.1)",
        },
    },

    // Buttons
    button: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        padding: `${spacing.sm} ${spacing.lg}`,
        backgroundColor: colors.primary,
        color: "#FFFFFF",
        border: "none",
        borderRadius: "20px",
        fontWeight: typography.fontWeightMedium,
        fontSize: typography.label.fontSize,
        lineHeight: typography.label.lineHeight,
        cursor: "pointer",
        transition: transitions.default,
        boxShadow: "0 2px 8px rgba(0,0,0,0.2)",
        "&:hover": {
            backgroundColor: colors.primaryLight,
            transform: "translateY(-1px)",
            boxShadow: "0 4px 12px rgba(0,0,0,0.25)",
        },
        "&:active": {
            backgroundColor: colors.primaryDark,
            transform: "translateY(1px)",
            boxShadow: "0 1px 3px rgba(0,0,0,0.15)",
        },
        "&:disabled": {
            backgroundColor: colors.surfaceElevated,
            color: colors.textDisabled,
            cursor: "not-allowed",
            boxShadow: "none",
        },
    },

    secondaryButton: {
        backgroundColor: "transparent",
        border: `1px solid ${colors.divider}`,
        color: colors.textPrimary,
        "&:hover": {
            backgroundColor: colors.surfaceElevated,
            borderColor: colors.textTertiary,
        },
    },

    dangerButton: {
        backgroundColor: colors.error,
        "&:hover": {
            backgroundColor: "#E03131",
        },
    },

    // Tables
    table: {
        width: "100%",
        borderCollapse: "separate",
        borderSpacing: 0,
        backgroundColor: colors.surface,
        border: `1px solid ${colors.divider}`,
        borderRadius: borderRadius.lg,
        overflow: "hidden",
        boxShadow: shadows.sm,
    },

    tableHeader: {
        padding: spacing.md,
        textAlign: "left",
        fontSize: typography.label.fontSize,
        fontWeight: typography.fontWeightMedium,
        color: colors.textSecondary,
        backgroundColor: colors.surfaceElevated,
        borderBottom: `1px solid ${colors.divider}`,
    },

    tableCell: {
        padding: spacing.md,
        fontSize: typography.body.fontSize,
        lineHeight: typography.body.lineHeight,
        color: colors.textPrimary,
        borderBottom: `1px solid ${colors.divider}`,
        transition: transitions.default,
    },

    // Empty states
    emptyState: {
        textAlign: "center",
        padding: spacing.xxl,
        color: colors.textSecondary,
    },

    // Thought card
    thoughtCard: {
        backgroundColor: colors.surfaceElevated,
        borderRadius: borderRadius.lg,
        padding: spacing.lg,
        marginBottom: spacing.md,
        boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
        border: `1px solid ${colors.divider}`,
        transition: transitions.default,
        cursor: "pointer",
        "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: "0 4px 12px rgba(0,0,0,0.2)",
            borderColor: `rgba(113, 183, 255, 0.3)`,
        },
    },

    // App header
    appHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: spacing.xl,
        paddingBottom: spacing.lg,
        borderBottom: `1px solid ${colors.divider}`,
    },

    appLogo: {
        fontSize: typography.h1.fontSize,
        fontWeight: typography.h1.fontWeight,
        color: colors.textPrimary,
        margin: 0,
        letterSpacing: "-0.5px",
    },

    // User profile display
    userProfile: {
        display: "flex",
        alignItems: "center",
        gap: spacing.md,
    },

    // Importance indicator
    importanceIndicator: (value) => {
        const getColor = () => {
            if (value <= 1) return "#E9ECEF";
            if (value <= 3) return colors.warning;
            return colors.error;
        };

        return {
            display: "inline-block",
            width: "32px",
            height: "32px",
            lineHeight: "32px",
            textAlign: "center",
            borderRadius: borderRadius.circle,
            backgroundColor: getColor(),
            color: value <= 1 ? colors.textSecondary : "#FFF",
            fontWeight: typography.fontWeightBold,
        };
    },

    // Tag display in table
    tagPill: {
        display: "inline-block",
        padding: `${spacing.xs} ${spacing.sm}`,
        margin: "0 4px 4px 0",
        backgroundColor: "rgba(113, 183, 255, 0.15)",
        color: colors.textPrimary,
        borderRadius: "12px",
        fontSize: typography.caption.fontSize,
        fontWeight: typography.fontWeightMedium,
        border: "1px solid rgba(113, 183, 255, 0.3)",
    },
}; 