import { useState } from 'react';
import { CONSTANTS } from '../styles/styles';

/**
 * Custom hook for managing tags input, selection, and filtering
 */
const useTags = (initialTags = []) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const [filteredTags, setFilteredTags] = useState([]);
    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const [availableTags, setAvailableTags] = useState(initialTags);

    // Add a tag to selected tags
    const addTag = (tag) => {
        if (!selectedTags.includes(tag) && selectedTags.length < CONSTANTS.MAX_TAGS) {
            setSelectedTags([...selectedTags, tag]);
            setTagInput('');
            setShowTagDropdown(false);
        }
    };

    // Remove a tag from selected tags
    const removeTag = (tagToRemove) => {
        setSelectedTags(selectedTags.filter(tag => tag !== tagToRemove));
    };

    // Handle tag input change
    const handleTagInputChange = (e) => {
        const val = e.target.value;
        setTagInput(val);
        setShowTagDropdown(true);

        // Filter tags for autocomplete
        if (val.trim() === '') {
            setFilteredTags(availableTags.slice(0, CONSTANTS.MAX_DROPDOWN_ITEMS));
        } else {
            setFilteredTags(
                availableTags
                    .filter(t => t.toLowerCase().includes(val.toLowerCase()))
                    .slice(0, CONSTANTS.MAX_DROPDOWN_ITEMS)
            );
        }
    };

    // Handle tag input key press
    const handleTagKeyPress = (e) => {
        if (e.key === 'Enter' && tagInput.trim() !== '') {
            e.preventDefault();
            addTag(tagInput.trim());
        }
    };

    // Update available tags
    const updateAvailableTags = (newTags) => {
        setAvailableTags(newTags);
        setFilteredTags(newTags.slice(0, CONSTANTS.MAX_DROPDOWN_ITEMS));
    };

    // Reset all tag state
    const resetTags = () => {
        setSelectedTags([]);
        setTagInput('');
        setShowTagDropdown(false);
    };
    
    // Set tags programmatically (used when loading tags from a thought)
    const setTags = (tags) => {
        setSelectedTags(tags);
    };

    return {
        selectedTags,
        tagInput,
        filteredTags,
        showTagDropdown,
        setShowTagDropdown,
        addTag,
        removeTag,
        handleTagInputChange,
        handleTagKeyPress,
        updateAvailableTags,
        resetTags,
        setTags
    };
};

export default useTags; 