import React from 'react';
import { styles, colors } from '../styles/styles';

/**
 * Elegant navigation tabs with premium Apple-inspired design
 * Shows only icons without text labels
 */
const Tabs = ({ activeTab, onTabChange, inboxCount = 0 }) => {
    return (
        <div style={styles.tabs}>
            <button
                onClick={() => onTabChange("inbox")}
                style={{
                    ...styles.tab(activeTab === "inbox"),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <svg width="22" height="22" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g transform="translate(0, 2)">
                            <path
                                d="M21 8l-3-6H6L3 8v8h18V8zm0 0H3"
                                stroke={activeTab === "inbox" ? colors.accent.blue : colors.textSecondary}
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M9 8c0 1.5 1.5 3 3 3s3-1.5 3-3"
                                stroke={activeTab === "inbox" ? colors.accent.blue : colors.textSecondary}
                                strokeWidth="2"
                                strokeLinecap="round"
                            />
                        </g>
                    </svg>
                    
                    {inboxCount > 0 && (
                        <span style={{
                            backgroundColor: colors.accent.purple,
                            color: 'white',
                            borderRadius: '50%',
                            width: '18px',
                            height: '18px',
                            fontSize: '11px',
                            fontWeight: '600',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginLeft: '4px',
                            boxShadow: '0 1px 2px rgba(0,0,0,0.2)',
                        }}>
                            {inboxCount > 99 ? '99+' : inboxCount}
                        </span>
                    )}
                </div>
            </button>
            <button
                onClick={() => onTabChange("library")}
                style={{
                    ...styles.tab(activeTab === "library"),
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <svg width="22" height="22" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g transform="translate(0, 2)">
                        <path
                            d="M6 2v14M11 2v14"
                            stroke={activeTab === "library" ? colors.accent.blue : colors.textSecondary}
                            strokeWidth="2"
                            strokeLinecap="round"
                        />
                        <path
                            d="M16 2v14h4V6l-4-4z"
                            stroke={activeTab === "library" ? colors.accent.blue : colors.textSecondary}
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            fill="none"
                        />
                    </g>
                </svg>
            </button>
        </div>
    );
};

export default Tabs; 