import { useState } from 'react';
import { CONSTANTS } from '../styles/styles';

/**
 * Custom hook for autocomplete fields like theme and motif
 */
const useAutocomplete = (initialOptions = []) => {
    const [value, setValue] = useState('');
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [options, setOptions] = useState(initialOptions);

    // Handle input change and filter options
    const handleInputChange = (e) => {
        console.log('Input value changing to:', e.target.value);
        const inputValue = e.target.value;
        setValue(inputValue);

        // Filter options based on input
        if (inputValue.trim() === '') {
            console.log('Showing all options');
            setFilteredOptions(options.slice(0, CONSTANTS.MAX_DROPDOWN_ITEMS));
        } else {
            console.log('Filtering options for:', inputValue);
            setFilteredOptions(
                options
                    .filter(option => option.toLowerCase().includes(inputValue.toLowerCase()))
                    .slice(0, CONSTANTS.MAX_DROPDOWN_ITEMS)
            );
        }
    };

    // Select an option from the dropdown
    const selectOption = (option) => {
        setValue(option);
        setFilteredOptions([]);
    };

    // Show all available options (limited)
    const showOptions = () => {
        setFilteredOptions(options.slice(0, CONSTANTS.MAX_DROPDOWN_ITEMS));
    };

    // Hide options
    const hideOptions = () => {
        setTimeout(() => setFilteredOptions([]), CONSTANTS.DROPDOWN_CLOSE_DELAY);
    };

    // Update available options
    const updateOptions = (newOptions) => {
        setOptions(newOptions);
    };

    // Reset the input
    const resetInput = () => {
        setValue('');
        setFilteredOptions([]);
    };

    return {
        value,
        filteredOptions,
        handleInputChange,
        selectOption,
        showOptions,
        hideOptions,
        updateOptions,
        resetInput
    };
};

export default useAutocomplete; 