import React, { useState, useEffect } from 'react';
import { colors } from '../styles/styles';

/**
 * Bottom drawer for filtering thoughts by tags
 */
const FilterDrawer = ({ isVisible, onClose, onFilterChange, currentFilters, thoughts }) => {
    // Extract unique tags from thoughts
    const [availableTags, setAvailableTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState(currentFilters?.tags || []);
    
    // Extract unique tags and count their frequency when thoughts change
    useEffect(() => {
        // Create a map to count tag occurrences
        const tagCounts = {};
        
        // Count occurrences of each tag
        thoughts.forEach(thought => {
            if (thought.tags && Array.isArray(thought.tags)) {
                thought.tags.forEach(tag => {
                    if (tag && tag.trim() !== '') {
                        tagCounts[tag] = (tagCounts[tag] || 0) + 1;
                    }
                });
            }
        });
        
        // Convert to array and sort by frequency (descending)
        const sortedTags = Object.entries(tagCounts)
            .sort((a, b) => b[1] - a[1])  // Sort by count (descending)
            .map(([tag]) => tag);  // Only keep the tag name
            
        setAvailableTags(sortedTags);
    }, [thoughts]);
    
    // Reset selected filters when drawer opens
    useEffect(() => {
        if (isVisible) {
            setSelectedTags(currentFilters?.tags || []);
        }
    }, [isVisible, currentFilters]);
    
    // Toggle a tag in the selected tags
    const toggleTag = (tag) => {
        setSelectedTags(prev => {
            if (prev.includes(tag)) {
                // Remove if already selected
                return prev.filter(item => item !== tag);
            } else {
                // Add if not selected
                return [...prev, tag];
            }
        });
    };
    
    // Apply the selected filters
    const applyFilters = () => {
        // Create a filter object with just tags property
        const newFilters = { tags: selectedTags };
        onFilterChange(newFilters);
        onClose();
    };
    
    // Clear all filters
    const clearFilters = () => {
        setSelectedTags([]);
    };
    
    // Check if we have any tags to show
    const hasTags = availableTags.length > 0;
    
    // Check if any tags are currently selected
    const hasSelectedTags = selectedTags.length > 0;

    return (
        <>
            {/* Backdrop overlay */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 999,
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 0.3s ease',
                    pointerEvents: isVisible ? 'auto' : 'none',
                }}
                onClick={onClose}
            />

            {/* Bottom drawer */}
            <div style={{
                position: 'fixed',
                bottom: isVisible ? 0 : '-80vh',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                maxWidth: '952px',
                backgroundColor: colors.surfaceElevated,
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                boxShadow: '0px -4px 24px rgba(0, 0, 0, 0.15)',
                transition: 'bottom 0.3s ease',
                maxHeight: '80vh',
                overflowY: 'auto',
                paddingTop: '16px',
                paddingBottom: '24px'
            }}>
                {/* Header */}
                <div style={{
                    padding: '8px 16px 16px',
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}>
                        <h2 style={{
                            fontSize: '18px',
                            fontWeight: '600',
                            color: colors.textPrimary,
                            margin: 0,
                        }}>
                            Filter by Tags
                        </h2>
                        
                        {hasSelectedTags && (
                            <button
                                onClick={clearFilters}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    color: colors.accent.blue,
                                    fontSize: '14px',
                                    fontWeight: '500',
                                    cursor: 'pointer',
                                    padding: '4px 8px',
                                }}
                            >
                                Clear All
                            </button>
                        )}
                    </div>
                </div>

                {hasTags ? (
                    <div style={{ padding: '16px' }}>
                        {/* Tags section */}
                        <div style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                        }}>
                            {availableTags.map(tag => (
                                <button
                                    key={tag}
                                    onClick={() => toggleTag(tag)}
                                    style={{
                                        backgroundColor: selectedTags.includes(tag) 
                                            ? colors.accent.blue 
                                            : colors.surface,
                                        color: selectedTags.includes(tag) 
                                            ? 'white' 
                                            : colors.textPrimary,
                                        border: `1px solid ${selectedTags.includes(tag) 
                                            ? colors.accent.blue 
                                            : colors.divider}`,
                                        borderRadius: '16px',
                                        padding: '6px 12px',
                                        fontSize: '14px',
                                        fontWeight: '500',
                                        cursor: 'pointer',
                                        transition: 'all 0.2s ease',
                                    }}
                                >
                                    {tag}
                                </button>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div style={{ 
                        padding: '32px 16px', 
                        textAlign: 'center',
                        color: colors.textSecondary 
                    }}>
                        <p>No tags available for filtering</p>
                    </div>
                )}

                {/* Action buttons */}
                <div style={{ 
                    padding: '8px 16px 0',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <button
                        onClick={applyFilters}
                        style={{
                            padding: '12px 24px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: colors.accent.blue,
                            cursor: 'pointer',
                        }}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </>
    );
};

export default FilterDrawer; 