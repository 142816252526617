import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { colors } from '../styles/styles';

const ComposePage = ({ onSave, isEditing = false, thoughts = [] }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    
    // Find the thought if in editing mode
    const existingThought = isEditing && id ? thoughts.find(t => t.id === id) : null;
    
    const [thought, setThought] = useState(existingThought ? existingThought.thought : '');
    const [isSaving, setIsSaving] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    
    // Check screen size for responsive behavior
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSave = async (keepOpen = false) => {
        if (!thought.trim() || isSaving) return;

        setIsSaving(true);

        try {
            await onSave({
                ...(existingThought && { id: existingThought.id }), // Preserve ID if editing
                thought,
                theme: existingThought?.theme || "",
                motif: existingThought?.motif || "",
                importance: existingThought?.importance || null,
                tags: existingThought?.tags || [],
                ...(existingThought && { timestamp: existingThought.timestamp }) // Preserve original timestamp
            });
            
            if (keepOpen) {
                // Clear the input but keep the page open
                setThought('');
                setIsSaving(false);
            } else {
                // Navigate back to detail view if editing, otherwise to home
                if (isEditing) {
                    navigate(`/thought/${id}`);
                } else {
                    navigate('/');
                }
            }
        } catch (error) {
            console.error('Error saving thought:', error);
            setIsSaving(false);
        }
    };

    // Mobile view - full screen
    if (isMobile) {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: colors.background,
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                }}>
                    <button 
                        onClick={() => isEditing ? navigate(`/thought/${id}`) : navigate('/')}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '8px',
                        }}
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 18L9 12L15 6" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {!isEditing && (
                            <button 
                                onClick={() => handleSave(true)}
                                disabled={!thought.trim() || isSaving}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: colors.accent.blue,
                                    border: `1px solid ${colors.accent.blue}`,
                                    borderRadius: '20px',
                                    padding: '8px 16px',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    cursor: thought.trim() && !isSaving ? 'pointer' : 'default',
                                    opacity: thought.trim() && !isSaving ? 1 : 0.5,
                                }}
                            >
                                Add another
                            </button>
                        )}
                        <button 
                            onClick={() => handleSave(false)}
                            disabled={!thought.trim() || isSaving}
                            style={{
                                backgroundColor: colors.accent.blue,
                                color: colors.textPrimary,
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: thought.trim() && !isSaving ? 'pointer' : 'default',
                                opacity: thought.trim() && !isSaving ? 1 : 0.5,
                            }}
                        >
                            {isSaving ? 'Saving...' : isEditing ? 'Update' : 'Save'}
                        </button>
                    </div>
                </div>
                
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'auto' }}>
                    <textarea
                        value={thought}
                        onChange={(e) => setThought(e.target.value)}
                        placeholder="What are you thinking?"
                        style={{
                            flex: 1,
                            border: 'none',
                            padding: '16px',
                            fontSize: '18px',
                            resize: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            color: colors.textPrimary,
                        }}
                        autoFocus
                    />
                </div>
            </div>
        );
    }

    // Desktop view - popup with darkened background
    return (
        <>
            {/* Darkened background overlay */}
            <div 
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: colors.background,
                    zIndex: 999,
                }}
                onClick={() => isEditing ? navigate(`/thought/${id}`) : navigate('/')}
            />
            
            {/* Popup container */}
            <div style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '600px',
                maxWidth: '90%',
                backgroundColor: colors.surfaceElevated,
                borderRadius: '16px',
                boxShadow: '0 10px 25px rgba(0, 0, 0, 0.5)',
                zIndex: 1000,
                display: 'flex',
                flexDirection: 'column',
                maxHeight: '80vh',
                overflow: 'hidden',
            }}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                }}>
                    <button 
                        onClick={() => isEditing ? navigate(`/thought/${id}`) : navigate('/')}
                        style={{
                            background: 'none',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '8px',
                        }}
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18M6 6L18 18" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                    <div style={{ display: 'flex', gap: '8px' }}>
                        {!isEditing && (
                            <button 
                                onClick={() => handleSave(true)}
                                disabled={!thought.trim() || isSaving}
                                style={{
                                    backgroundColor: 'transparent',
                                    color: colors.accent.blue,
                                    border: `1px solid ${colors.accent.blue}`,
                                    borderRadius: '20px',
                                    padding: '8px 16px',
                                    fontWeight: 'bold',
                                    fontSize: '12px',
                                    cursor: thought.trim() && !isSaving ? 'pointer' : 'default',
                                    opacity: thought.trim() && !isSaving ? 1 : 0.5,
                                }}
                            >
                                Add another
                            </button>
                        )}
                        <button 
                            onClick={() => handleSave(false)}
                            disabled={!thought.trim() || isSaving}
                            style={{
                                backgroundColor: colors.accent.blue,
                                color: colors.textPrimary,
                                border: 'none',
                                borderRadius: '20px',
                                padding: '8px 16px',
                                fontWeight: 'bold',
                                fontSize: '12px',
                                cursor: thought.trim() && !isSaving ? 'pointer' : 'default',
                                opacity: thought.trim() && !isSaving ? 1 : 0.5,
                            }}
                        >
                            {isSaving ? 'Saving...' : isEditing ? 'Update' : 'Save'}
                        </button>
                    </div>
                </div>
                
                <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    maxHeight: '80vh',
                    overflow: 'auto',
                    overflowX: 'hidden',
                }}>
                    <textarea
                        value={thought}
                        onChange={(e) => setThought(e.target.value)}
                        placeholder="What are you thinking?"
                        style={{
                            minHeight: '180px',
                            border: 'none',
                            padding: '16px',
                            fontSize: '18px',
                            resize: 'none',
                            outline: 'none',
                            backgroundColor: 'transparent',
                            color: colors.textPrimary,
                        }}
                        autoFocus
                    />
                </div>
                <style>{`
                    div::-webkit-scrollbar {
                        display: none;
                    }

                `}</style>
            </div>
        </>
    );
};

export default ComposePage; 