/**
 * Process thoughts data to extract unique values sorted by frequency
 * @param {Array} data - Array of thought objects from Firestore
 * @returns {Object} Object containing sorted themes, motifs, and tags
 */
export const extractUniqueValues = (data) => {
    const themeCounts = {};
    const motifCounts = {};
    const tagCounts = {};

    // Count occurrences of each value
    data.forEach(doc => {
        // Count theme occurrences
        if (doc.theme) {
            themeCounts[doc.theme] = (themeCounts[doc.theme] || 0) + 1;
        }

        // Count motif occurrences
        if (doc.motif) {
            motifCounts[doc.motif] = (motifCounts[doc.motif] || 0) + 1;
        }

        // Count tag occurrences
        if (doc.tags && Array.isArray(doc.tags)) {
            doc.tags.forEach(tag => {
                tagCounts[tag] = (tagCounts[tag] || 0) + 1;
            });
        }
    });

    // Sort values by frequency (descending)
    const sortedThemes = Object.entries(themeCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([theme]) => theme);

    const sortedMotifs = Object.entries(motifCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([motif]) => motif);

    const sortedTags = Object.entries(tagCounts)
        .sort((a, b) => b[1] - a[1])
        .map(([tag]) => tag);

    return {
        themes: sortedThemes,
        motifs: sortedMotifs,
        tags: sortedTags
    };
};

/**
 * Formats a timestamp for display
 * @param {string} timestamp - ISO timestamp string
 * @returns {string} Formatted date string (e.g. "March 25, 2025, 7:50 AM")
 */
export const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    };
    const timeOptions = {
        hour: 'numeric',
        minute: '2-digit',
        hour12: true
    };

    const dateStr = date.toLocaleDateString('en-US', options);
    const timeStr = date.toLocaleTimeString('en-US', timeOptions);

    return `${dateStr}, ${timeStr}`;
}; 