import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { styles, colors } from '../styles/styles';
import { formatTimestamp } from '../utils/dataProcessing';
import { useSwipeable } from 'react-swipeable';

const ThoughtDetail = ({ thoughts, onSave, updateThoughtLocally, isDataLoading = false }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [thought, setThought] = useState(null);
    
    // Keep a ref to the current UI value to prevent re-renders
    const importanceValueRef = useRef(null);
    const [isSaving, setIsSaving] = useState(false);
    const [saveTimeout, setSaveTimeout] = useState(null);
    
    // Use both isDataLoading from props and local loading state
    const [isLocalLoading, setIsLocalLoading] = useState(true);
    const isLoading = isDataLoading || isLocalLoading;
    // Fade in animation state
    const [isVisible, setIsVisible] = useState(false);

    // Create a function for back navigation to use in both button and swipe
    const handleBack = () => {
        // Apply exit animation before navigation
        const detailContainer = document.querySelector('.page-content');
        if (detailContainer) {
            detailContainer.style.animation = 'fadeOut 0.2s cubic-bezier(0.2, 0, 0, 1) forwards';
            setTimeout(() => {
                navigate('/', { replace: true });
            }, 180);
        } else {
            navigate('/', { replace: true });
        }
    };

    // Swipe handlers for going back
    const swipeHandlers = useSwipeable({
        onSwipedRight: (eventData) => {
            // Only trigger "go back" if swipe started near the left edge (within 50px)
            if (eventData.initial[0] < 50) {
                handleBack();
            }
        },
        trackMouse: false, // Only detect touch swipes, not mouse
        delta: 50, // Minimum swipe distance required
        preventDefaultTouchmoveEvent: false, // Don't prevent vertical scrolling
    });

    // Update the thought when thoughts array changes or id changes
    useEffect(() => {
        const currentThought = thoughts.find(t => t.id === id);
        setThought(currentThought);
        if (currentThought) {
            // Explicitly set to null if importance is not defined
            importanceValueRef.current = currentThought.importance !== undefined ? currentThought.importance : null;
            
            // Force update the slider UI elements
            updateSliderUI(importanceValueRef.current);
        }
    }, [thoughts, id]);

    // Clean up timeout on unmount
    useEffect(() => {
        return () => {
            if (saveTimeout) clearTimeout(saveTimeout);
        };
    }, [saveTimeout]);

    // Set loading to false after a short delay to ensure data is ready
    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLocalLoading(false);
        }, 100); // Updated to match FieldEditPage's 100ms delay
        return () => clearTimeout(timer);
    }, []);

    // Trigger fade in animation after component mounts and loading is done
    useEffect(() => {
        if (!isLoading && thought) {
            // Small delay to ensure DOM is ready
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 10); // Reduced delay to match FieldEditPage
            return () => clearTimeout(timer);
        }
    }, [isLoading, thought]);

    // Function to directly update the UI without a state change
    const updateSliderUI = (value) => {
        // Update "Not set" button
        const notSetButton = document.querySelector('.importance-not-set');
        if (notSetButton) {
            notSetButton.style.backgroundColor = value === null ? `${colors.accent.blue}20` : 'transparent';
            notSetButton.style.border = value === null ? `1px solid ${colors.accent.blue}` : '1px solid transparent';
            notSetButton.style.color = value === null ? colors.accent.blue : colors.textSecondary;
            
            // Update the check mark visibility
            const checkMark = notSetButton.querySelector('svg');
            if (checkMark) {
                checkMark.style.display = value === null ? 'block' : 'none';
            }
        }
        
        // Update slider filled portion
        const sliderFill = document.querySelector('.importance-slider-fill');
        if (sliderFill && value !== null) {
            sliderFill.style.width = value === 1 ? '5%' : `${((value - 1) / 4) * 100}%`;
            sliderFill.style.display = 'block';
        } else if (sliderFill) {
            sliderFill.style.display = 'none';
        }
        
        // Update number buttons
        const numberButtons = document.querySelectorAll('.importance-number');
        if (numberButtons) {
            numberButtons.forEach((button, index) => {
                const level = index + 1;
                button.style.color = value === level ? colors.accent.purple : colors.textSecondary;
            });
        }
    };

    // Auto-save the importance value after a brief delay
    const handleImportanceChange = (value) => {
        // Store the value in ref without causing re-render
        importanceValueRef.current = value;
        
        // Directly update the UI elements
        updateSliderUI(value);
        
        // Clear any existing timeout
        if (saveTimeout) clearTimeout(saveTimeout);
        
        // Set a new timeout to save after 500ms of inactivity
        const timeout = setTimeout(async () => {
            if (!isSaving && value !== thought.importance) {
                setIsSaving(true);
                
                // Update saving indicator without re-rendering
                const savingIndicator = document.querySelector('.importance-saving');
                if (savingIndicator) {
                    savingIndicator.style.display = 'block';
                }
                
                try {
                    const updatedThought = { ...thought, importance: value };
                    
                    // If updateThoughtLocally is available, use it for optimistic updates
                    if (updateThoughtLocally) {
                        // Optimistically update the local state first
                        updateThoughtLocally(updatedThought);
                        
                        // Then save to Firebase (without triggering a full data reload)
                        await onSave(updatedThought, true); // Pass flag to avoid reload
                    } else {
                        // Fallback to regular save that might reload all data
                        await onSave(updatedThought);
                    }
                    
                    // Update the local thought object directly
                    setThought(prev => ({ ...prev, importance: value }));
                } catch (error) {
                    console.error('Error saving importance:', error);
                } finally {
                    setIsSaving(false);
                    
                    // Hide saving indicator without re-rendering
                    if (savingIndicator) {
                        savingIndicator.style.display = 'none';
                    }
                }
            }
        }, 500);
        
        setSaveTimeout(timeout);
    };

    const formattedDate = thought ? formatTimestamp(thought.timestamp) : '';

    const handleEdit = () => {
        // Apply exit animation before navigation
        const detailContainer = document.querySelector('.page-content');
        if (detailContainer) {
            detailContainer.style.animation = 'fadeOut 0.2s cubic-bezier(0.2, 0, 0, 1) forwards';
            setTimeout(() => {
                navigate(`/edit/${thought.id}`);
            }, 180); // Slightly shorter than animation duration
        } else {
            navigate(`/edit/${thought.id}`);
        }
    };
    
    // Navigate to field-specific edit pages
    const navigateToFieldEdit = (fieldName) => {
        // Apply exit animation before navigation
        const detailContainer = document.querySelector('.page-content');
        if (detailContainer) {
            detailContainer.style.animation = 'fadeOut 0.2s cubic-bezier(0.2, 0, 0, 1) forwards';
            setTimeout(() => {
                navigate(`/edit-field/${thought.id}/${fieldName}`);
            }, 180); // Slightly shorter than animation duration
        } else {
            navigate(`/edit-field/${thought.id}/${fieldName}`);
        }
    };

    if (isLoading) {
        return (
            <div style={{ 
                padding: '24px 8px', 
                textAlign: 'center',
                animation: 'fadeIn 0.3s cubic-bezier(0.2, 0, 0, 1)',
                opacity: 0, // Start invisible for the animation
            }} className="page-content">
                {/* Empty loading state with no message */}
            </div>
        );
    }

    if (!thought) {
        return (
            <div style={{ 
                padding: '24px 8px', 
                textAlign: 'center',
                animation: 'fadeIn 0.3s cubic-bezier(0.2, 0, 0, 1)',
                opacity: 1,
            }} className="page-content">
                <p>Thought not found</p>
                <button 
                    onClick={() => navigate('/')} 
                    style={{ ...styles.button, marginTop: '16px' }}
                >
                    Back to Library
                </button>
            </div>
        );
    }

    return (
        <div 
            {...swipeHandlers}
            style={{ 
                padding: '8px 8px', 
                maxWidth: '800px', 
                margin: '0 auto',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.3s cubic-bezier(0.2, 0, 0, 1)',
                willChange: 'opacity',
            }}
            className="page-content"
        >
            {/* Define animations */}
            <style>
                {`
                    @keyframes fadeIn {
                        from { opacity: 0; }
                        to { opacity: 1; }
                    }
                    
                    @keyframes fadeOut {
                        from { opacity: 1; }
                        to { opacity: 0; }
                    }
                `}
            </style>
            
            {/* Back button and date/edit in same row */}
            <div style={{ 
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: '16px',
                paddingLeft: '0px',
            }}>
                {/* Back button (left side) */}
                <button 
                    onClick={handleBack} 
                    style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        marginLeft: '0px',
                    }}
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 18L9 12L15 6" stroke={colors.textPrimary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </button>
                
                {/* Date and edit on right side */}
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ fontSize: '14px', color: colors.textSecondary, marginRight: '8px' }}>{formattedDate}</span>
                    <button 
                        onClick={handleEdit}
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '8px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke={colors.textPrimary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.5 2.50001C18.8978 2.10219 19.4374 1.87869 20 1.87869C20.5626 1.87869 21.1022 2.10219 21.5 2.50001C21.8978 2.89784 22.1213 3.4374 22.1213 4.00001C22.1213 4.56262 21.8978 5.10219 21.5 5.50001L12 15L8 16L9 12L18.5 2.50001Z" stroke={colors.textPrimary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>

            {/* Main thought content */}
            <div 
                onClick={handleEdit}
                style={{ 
                    cursor: 'pointer', 
                    marginBottom: '24px',
                    position: 'relative',
                    padding: '16px',
                    backgroundColor: colors.surfaceElevated,
                    borderRadius: '12px',
                }}
            >
                <p style={{
                    fontSize: '18px',
                    lineHeight: '1.6',
                    color: colors.textPrimary,
                    whiteSpace: 'pre-wrap',
                    marginTop: 0,
                    marginBottom: 0,
                    fontWeight: '400',
                }}>
                    {thought.thought}
                </p>
            </div>

            {/* Metadata section */}
            <div style={{ 
                display: 'flex', 
                flexDirection: 'column',
                gap: '20px',
                paddingTop: '0px',
            }}>
                {/* Tags */}
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                    margin: '5px 0',
                    padding: '0 8px',
                }}>
                    <label style={{ 
                        display: 'block', 
                        fontSize: '14px',
                        color: colors.textSecondary,
                        marginBottom: '4px',
                        fontWeight: 'bold',
                    }}>
                        Tags
                    </label>
                    {thought.tags && thought.tags.length > 0 ? (
                        <div 
                            onClick={() => navigateToFieldEdit('tags')}
                            style={{ 
                                display: 'flex', 
                                flexWrap: 'wrap', 
                                gap: '8px',
                                cursor: 'pointer'
                            }}
                        >
                            {thought.tags.map((tag, i) => (
                                <span key={i} style={{
                                    ...styles.tagPill,
                                    backgroundColor: colors.surface,
                                    color: colors.accent.blue,
                                    boxShadow: 'none',
                                    border: `1px solid ${colors.accent.blue}20`,
                                    fontWeight: 'bold',
                                    fontSize: '14px',
                                }}>
                                    {tag}
                                </span>
                            ))}
                        </div>
                    ) : (
                        <div 
                            onClick={() => navigateToFieldEdit('tags')}
                            style={{ 
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                cursor: 'pointer',
                                color: colors.textSecondary,
                                fontSize: '17px',
                                fontWeight: 'bold',
                            }}
                        >
                            <span>No tags</span>
                            <svg width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 5V19M5 12H19" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </div>
                    )}
                </div>

                {/* Importance - Simple clean slider (now last) - using direct DOM manipulation */}
                <div style={{
                    margin: '5px 0',
                    padding: '0 8px',
                }}>
                    <div style={{ 
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '8px'
                    }}>
                        <label style={{ 
                            fontSize: '14px',
                            color: colors.textSecondary,
                            fontWeight: 'bold',
                        }}>
                            Importance
                        </label>
                        <span 
                            className="importance-saving"
                            style={{ 
                                fontSize: '14px',
                                color: colors.textSecondary,
                                opacity: 0.7,
                                fontWeight: 'bold',
                                display: isSaving ? 'block' : 'none',
                            }}
                        >
                            Saving...
                        </span>
                    </div>
                    
                    {/* "Not set" button */}
                    <div style={{ marginBottom: '12px', display: 'flex', alignItems: 'center' }}>
                        <button
                            className="importance-not-set"
                            type="button"
                            onClick={() => handleImportanceChange(null)}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '8px',
                                padding: '6px 10px',
                                backgroundColor: thought.importance === null ? `${colors.accent.blue}20` : 'transparent',
                                border: thought.importance === null ? `1px solid ${colors.accent.blue}` : '1px solid transparent',
                                borderRadius: '4px',
                                fontSize: '15px',
                                color: thought.importance === null ? colors.accent.blue : colors.textSecondary,
                                cursor: 'pointer',
                                transition: 'all 150ms ease',
                                fontWeight: 'bold',
                            }}
                        >
                            Not set
                            <svg 
                                width="12" 
                                height="12" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ display: thought.importance === null ? 'block' : 'none' }}
                            >
                                <path d="M5 12L10 17L20 7" stroke={colors.accent.blue} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </button>
                    </div>
                    
                    {/* Clean simple slider - without +/- signs */}
                    <div style={{
                        margin: '12px 0'
                    }}>
                        {/* Simple slider bar */}
                        <div style={{
                            position: 'relative',
                            height: '4px',
                            width: '100%',
                            backgroundColor: colors.divider,
                            borderRadius: '2px',
                            overflow: 'hidden',
                            cursor: 'pointer'
                        }}
                            onClick={(e) => {
                                // Calculate position as percentage of width
                                const rect = e.currentTarget.getBoundingClientRect();
                                const x = e.clientX - rect.left;
                                const percent = x / rect.width;
                                
                                // Convert to scale 1-5
                                const rawValue = 1 + Math.floor(percent * 5);
                                const value = Math.max(1, Math.min(5, rawValue));
                                
                                // Update importance
                                handleImportanceChange(value);
                            }}
                        >
                            {/* Filled portion */}
                            <div 
                                className="importance-slider-fill"
                                style={{
                                    position: 'absolute',
                                    left: 0,
                                    top: 0,
                                    bottom: 0,
                                    width: thought.importance === 1 ? '5%' : `${((thought.importance - 1) / 4) * 100}%`,
                                    backgroundColor: colors.accent.purple,
                                    transition: 'width 0.2s ease',
                                    display: thought.importance !== null ? 'block' : 'none'
                                }} 
                            />
                        </div>
                    </div>
                    
                    {/* Slider numbers */}
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        position: 'relative',
                        marginTop: '8px'
                    }}>
                        {[1, 2, 3, 4, 5].map(level => (
                            <button
                                key={level}
                                className="importance-number"
                                onClick={() => handleImportanceChange(level)}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    padding: '4px',
                                    cursor: 'pointer',
                                    fontSize: '14px',
                                    color: thought.importance === level ? colors.accent.purple : colors.textSecondary,
                                    fontWeight: 'bold',
                                }}
                            >
                                {level}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThoughtDetail; 