import React from 'react';
import { styles, colors } from '../styles/styles';
import { useLocation } from 'react-router-dom';

/**
 * Header component with brand name and user controls
 * Hidden on detail pages
 */
const Header = ({ user, onLogin, onLogout }) => {
    const location = useLocation();
    
    // Hide header on detail pages
    const isDetailPage = location.pathname.includes('/thought/') || 
                         location.pathname.includes('/edit/') ||
                         location.pathname.includes('/edit-field/') ||
                         location.pathname.includes('/compose');
    
    if (isDetailPage) {
        return null; // Don't render header on detail pages
    }
    
    return (
        <header style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '10px 24px',
            marginBottom: '16px',
            backgroundColor: colors.background,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1000,
            boxShadow: '0 1px 2px rgba(0,0,0,0.1)',
        }}>
            {/* Brand name */}
            <div style={{
                fontSize: '20px',
                fontWeight: '600',
                color: colors.textPrimary,
                letterSpacing: '-0.5px',
            }}>
                Psyche
            </div>

            {/* User controls */}
            {!user ? (
                <button
                    onClick={onLogin}
                    style={{
                        ...styles.button,
                        padding: '6px 16px',
                        fontSize: '14px',
                    }}
                >
                    Sign in
                </button>
            ) : (
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '12px'
                }}>
                    <p style={{
                        margin: '0',
                        fontSize: '16px',
                        fontWeight: '500',
                        color: colors.textPrimary
                    }}>
                        {user.displayName}
                    </p>
                    <button
                        onClick={onLogout}
                        style={{
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            padding: '6px',
                            color: colors.textSecondary,
                            display: 'flex',
                            alignItems: 'center',
                            transition: 'all 150ms ease',
                            borderRadius: '8px',
                        }}
                        title="Sign out"
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M16 17L21 12L16 7" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M21 12H9" stroke={colors.textSecondary} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                </div>
            )}
        </header>
    );
};

export default Header; 