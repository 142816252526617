import React, { useState, useEffect } from 'react';
import { colors } from '../styles/styles';

/**
 * Bottom drawer component for sorting thoughts
 */
const SortDrawer = ({ isVisible, onClose, onSortChange, currentSort }) => {
    const [selectedSort, setSelectedSort] = useState(currentSort || 'recent');

    // Reset selected sort when drawer opens
    useEffect(() => {
        if (isVisible) {
            setSelectedSort(currentSort || 'recent');
        }
    }, [isVisible, currentSort]);

    // Apply the selected sort
    const applySort = () => {
        onSortChange(selectedSort);
        onClose();
    };

    // Sort options
    const sortOptions = [
        {
            id: 'recent',
            label: 'Recently Added'
        },
        {
            id: 'importance',
            label: 'Importance'
        }
    ];

    return (
        <>
            {/* Backdrop overlay */}
            <div
                style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 999,
                    opacity: isVisible ? 1 : 0,
                    transition: 'opacity 0.3s ease',
                    pointerEvents: isVisible ? 'auto' : 'none',
                }}
                onClick={onClose}
            />

            {/* Bottom drawer */}
            <div style={{
                position: 'fixed',
                bottom: isVisible ? 0 : '-80vh',
                left: '50%',
                transform: 'translateX(-50%)',
                width: '100%',
                maxWidth: '952px',
                backgroundColor: colors.surfaceElevated,
                zIndex: 1000,
                borderTopLeftRadius: '16px',
                borderTopRightRadius: '16px',
                boxShadow: '0px -4px 24px rgba(0, 0, 0, 0.15)',
                transition: 'bottom 0.3s ease',
                maxHeight: '80vh',
                overflowY: 'auto',
                paddingTop: '16px',
                paddingBottom: '24px'
            }}>
                {/* Header */}
                <div style={{
                    padding: '8px 16px 16px',
                }}>
                    <h2 style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        color: colors.textPrimary,
                        margin: 0,
                    }}>
                        Sort By
                    </h2>
                </div>

                {/* Sort options */}
                <div style={{ padding: '8px 16px' }}>
                    {sortOptions.map((option) => (
                        <div
                            key={option.id}
                            onClick={() => setSelectedSort(option.id)}
                            style={{
                                padding: '16px',
                                marginBottom: '8px',
                                backgroundColor: option.id === selectedSort ? colors.surface : 'transparent',
                                borderRadius: '12px',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            {/* Option label */}
                            <div style={{
                                fontSize: '16px',
                                fontWeight: '400',
                                color: option.id === selectedSort ? colors.accent.blue : colors.textPrimary,
                            }}>
                                {option.label}
                            </div>
                        </div>
                    ))}
                </div>

                {/* Action buttons */}
                <div style={{
                    padding: '8px 16px 0',
                    display: 'flex',
                    justifyContent: 'center'
                }}>
                    <button
                        onClick={applySort}
                        style={{
                            padding: '12px 24px',
                            backgroundColor: 'transparent',
                            border: 'none',
                            fontSize: '16px',
                            fontWeight: '600',
                            color: colors.accent.blue,
                            cursor: 'pointer',
                        }}
                    >
                        Apply
                    </button>
                </div>
            </div>
        </>
    );
};

export default SortDrawer; 